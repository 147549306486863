import React from 'react';
import './App.css';
import TechLogo from './ttq.svg'
import Loader from './ai-loader.jpg'
function App() {

    const token = "chatgpt-api8.p.rapidapi.com";

    const e_token = "8d2304b685msh42cef50df0d88d6p1ae658jsnbfdba76ac12d";
    const [result, setResult] = React.useState();
    const [inputData, setInput] = React.useState();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(()=>{

    },[]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch(`https://${token}/`, {
            method: "POST",
            headers: {
                "X-RapidAPI-Host": token,
                "X-RapidAPI-Key": e_token,
                "Content-Type": "application/json",
                "User-Agent": "PostmanRuntime/7.31.3",
                "Accept": "*/*",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive"
            },
            body: JSON.stringify([
                {
                    role: "user",
                    content: inputData
                }
            ])
        })
            .then((res) => res.json())
            .then((json) => {
                // Handle the json response here
                setLoading(false)
                console.log(json);
                setResult(json)
                // const jsonString = JSON.stringify(result)
                // console.log(jsonString, "jsonString")
            })
            .catch((error) => {
                // Handle errors here
                console.error(error);
            });
            console.log(result, "--result1")
            // .finally(() => setLoading(false));
        // setLoading(false)
    }

    // {
    //     const jsonString = JSON.stringify(result)
    //     console.log(jsonString, "jsonString")
    // }

    return (
        <>

    <div className="tech-app">

        {/*<div>*/}
        {/*    {loading && <div className={'loader'}>*/}
        {/*        <img src={Loader} />*/}
        {/*    </div>}*/}
        {/*    /!* Rest of your component's JSX goes here *!/*/}
        {/*</div>*/}
        <div className={'container'}>
            <div className={'inner-app'}>
                <div className={'logo'}>
                    <img src={TechLogo} />
                </div>
                <div className={'tech-content'}>
                <textarea type={'text'} placeholder={'Send a message..'} onChange={(e)=>{
                    const value = e.target && e.target.value
                    setInput(value)
                }} />
                    <button onClick={handleSubmit}> {loading ? <div className={'loader'}><img src={Loader} /></div> : <a>Search</a> }</button>

                </div>
            </div>
            <div className={'tech-response'}>
            {result && <p className="typewriter">{result.text}</p>}
            </div>
        </div>
    </div>


    {/*{JSON.stringify(result)}*/}

        </> );
}

export default App;
